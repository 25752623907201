















































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  filters: {
    roundNumber(value: any) {
      return value.toFixed(2).replace("-", "−");
    },
  },
})
export default class MapView extends Vue {
  private response: any = null;
  private loading = false;
}
