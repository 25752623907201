import Vue from 'vue';
import App from './App.vue';
import createRouter from './router';
import BootstrapVue from 'bootstrap-vue';
import Sparkline from 'vue-sparklines';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Sparkline);

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

export default () => {
  const router = createRouter();
  return new Vue({
    router,
    render: h => h(App)
  });
};
