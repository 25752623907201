





































































































































import { Component, Vue } from "vue-property-decorator";
import Plotly from "plotly.js";
import DataService from "@/services/DataService";

@Component({
  components: {},
  filters: {
    roundNumber(value: any) {
      return value.toFixed(1).replace("-", "−");
    },
  },
})
export default class MapView extends Vue {
  private ds = new DataService();
  private response: any = null;
  private loading = false;

  private refLineStyle = {
    stroke: "#d14",
    strokeOpacity: 0,
    strokeDasharray: "2, 2",
  };

  private mounted() {
    this.renderMap();
  }

  public renderMap() {
    this.loading = true;
    this.ds.getData().then((response) => {
      this.loading = false;
      this.response = response;

      let locations = this.response.data.map((e: any) => e.c);
      let values = this.response.data.map((e: any) => e.g - e.p);
      let texts = this.response.data.map(
        (e: any) => e.n + ": " + e.g.toFixed(1) + "%"
      );

      const maxOfMax = Math.max.apply(Math, values);
      const maxOfMin = Math.abs(Math.min.apply(Math, values));
      let maxMax = Math.max.apply(Math, [maxOfMax, maxOfMin]);
      if (maxMax > 8) {
        maxMax = 8;
      }

      const scale = [
        [0.0, "#790000"],
        [0.125, "#bb2c22"],
        [0.25, "#eb6955"],
        [0.375, "#fcaf9c"],
        [0.5, "#f5f5f5"],
        [0.625, "#59d786"],
        [0.75, "#1ba255"],
        [0.875, "#006d28"],
        [1.0, "#003c00"],
      ];

      var data = [
        {
          type: "choroplethmapbox",
          geojson:
            "https://raw.githubusercontent.com/johan/world.geo.json/master/countries.geo.json",
          locations: locations,
          z: values,
          text: texts,
          zmin: -maxMax,
          zmax: maxMax,
          colorscale: scale,
          marker: {
            opacity: 0.7,
          },
          showscale: false,
          hoverinfo: "text",
        },
      ];

      var layout = {
        mapbox: {
          style: "carto-positron",
          center: { lon: -110, lat: 50 },
          zoom: 0.8,
        },
        height: 500,
        margin: { t: 0, b: 0, r: 0, l: 0 },
        font: {
          family: "sans-serif",
        },
        coloraxis: { showscale: false },
      };

      Plotly.newPlot("growth-map", data as any, layout, {
        responsive: true,
        displayModeBar: false,
        scrollZoom: false,
      });
    });
  }
}
