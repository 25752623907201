



















































































































import { Component, Vue } from "vue-property-decorator";
import Plotly from "plotly.js";
import DataService from "@/services/DataService";

@Component({
  components: {},
  filters: {
    roundNumber(value: any) {
      if (value === "-") {
        return "–";
      }
      try {
        return value.toFixed(2).replace("-", "−");
      } catch (error) {
        return "−";
      }
    },
  },
})
export default class MapView extends Vue {
  private ds = new DataService();
  private response: any = null;
  private loading = false;

  private refLineStyle = {
    stroke: "#d14",
    strokeOpacity: 0,
    strokeDasharray: "2, 2",
  };

  private mounted() {
    this.renderMap();
  }

  public renderMap() {
    this.loading = true;
    this.ds.getCurves().then((response) => {
      this.loading = false;
      this.response = response;

      var data = [
        {
          x: this.response.curves.usa.x,
          y: this.response.curves.usa.y,
          name: "United States",
          line: { width: 1.5, color: "#173530" },
          type: "scatter",
        },
        {
          x: this.response.curves.gbr.x,
          y: this.response.curves.gbr.y,
          name: "United Kingdom",
          line: { width: 1.5, color: "#C4BD97" },
          type: "scatter",
        },
        {
          x: this.response.curves.deu.x,
          y: this.response.curves.deu.y,
          name: "Germany",
          line: { width: 1.5, color: "#75A570" },
          type: "scatter",
        },
        {
          x: this.response.curves.jpn.x,
          y: this.response.curves.jpn.y,
          name: "Japan",
          line: { width: 1.5, color: "#0070C0" },
          type: "scatter",
        },
        {
          x: this.response.curves.chn.x,
          y: this.response.curves.chn.y,
          name: "China",
          line: { width: 1.5, color: "#FF0000" },
          type: "scatter",
        },
      ];

      const layout = {
        margin: { t: 10, b: 20, autoexpand: true },
        font: {
          family:
            "'Titillium Web', Helvetica Neue, Helvetica, Arial, sans-serif",
        },
        xaxis: {
          showgrid: false,
          showline: true,
          // hoverformat: hoverFormat,
          zerolinecolor: "#d2d2d2",
          automargin: true,
          linecolor: "#d2d2d2",
          // ticks: theme === "default" ? "" : "outside",
          tickcolor: "#d2d2d2",
          // zeroline: !isHistogram
        },
        yaxis: {
          showline: true,
          showgrid: false,
          hoverformat: ".2f",
          tickformat: ".2f",
          zerolinecolor: "#d2d2d2",
          automargin: true,
          gridwidth: 0,
          linecolor: "#d2d2d2",
          tickcolor: "#d2d2d2",
        },
        hoverlabel: {
          font: {
            size: 10,
          },
          namelength: 100,
        },
        legend: {
          orientation: "h",
          xanchor: "center",
          x: 0.5,
          y: 1.01,
          yanchor: "bottom",
          font: {
            //   size: legendFontSize,
          },
        },
        showlegend: true,
      };

      Plotly.newPlot("growth-map", data as any, layout as any, {
        responsive: true,
        displayModeBar: false,
        scrollZoom: false,
      });
    });
  }
}
