import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import MapView from '../views/MapView.vue';
import CurveView from '../views/CurveView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Augur Labs' }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/growth',
    name: 'growth',
    component: MapView,
    meta: { title: 'Augur Labs Timely Underlying Growth Indicator' }
  },
  {
    path: '/curve',
    name: 'curve',
    component: CurveView,
    meta: { title: 'Augur Labs Constant Maturity Yield Curves' }
  }
];

export default () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });

  router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title =
      toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Augur Labs';

    next();
  });
  return router;
};
