// import axios, { AxiosResponse } from "axios";
import axios from "@/services/api";

export default class DataService {


    public async getData() {
        try {
            const response = await axios.get("/api/v1/growth/map/");
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async getCurves() {
        try {
            const response = await axios.get("/api/v1/curve/summary/");
            return response.data;
        } catch (error) {
            return 0;
        }
    }


}
